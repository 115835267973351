export {
    GetAvailableShippingRatesDocument,
    type GetAvailableShippingRatesQuery,
    type GetAvailableShippingRatesQueryVariables,
} from './GetAvailableShippingRatesQuery.generated';
export {
    GetCheckoutCompletedTimeDocument,
    type GetCheckoutCompletedTimeQuery,
    type GetCheckoutCompletedTimeQueryVariables,
} from './GetCheckoutCompletedTime.generated';
