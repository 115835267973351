import * as Types from '@lib/machine-parts/types/graphql/storefront';

import { gql } from '@apollo/client';
import { CheckoutWithShippingRatesFragmentDoc } from '../fragments/checkoutFragmentWithShippingRates.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAvailableShippingRatesQueryVariables = Types.Exact<{
    checkoutId: Types.Scalars['ID'];
    country?: Types.InputMaybe<Types.CountryCode>;
}>;

export type GetAvailableShippingRatesQuery = {
    __typename?: 'QueryRoot';
    node?:
        | { __typename?: 'AppliedGiftCard'; id: string }
        | { __typename?: 'Article'; id: string }
        | { __typename?: 'Blog'; id: string }
        | { __typename?: 'Cart'; id: string }
        | { __typename?: 'CartLine'; id: string }
        | {
              __typename?: 'Checkout';
              id: string;
              webUrl: string;
              currencyCode: Types.CurrencyCode;
              email?: string | undefined;
              note?: string | undefined;
              taxExempt: boolean;
              taxesIncluded: boolean;
              availableShippingRates?:
                  | {
                        __typename?: 'AvailableShippingRates';
                        ready: boolean;
                        shippingRates?:
                            | Array<{
                                  __typename?: 'ShippingRate';
                                  handle: string;
                                  title: string;
                                  price: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
                              }>
                            | undefined;
                    }
                  | undefined;
              buyerIdentity: { __typename?: 'CheckoutBuyerIdentity'; countryCode?: Types.CountryCode | undefined };
              customAttributes: Array<{ __typename?: 'Attribute'; key: string; value?: string | undefined }>;
              lineItems: {
                  __typename?: 'CheckoutLineItemConnection';
                  nodes: Array<{
                      __typename?: 'CheckoutLineItem';
                      id: string;
                      quantity: number;
                      title: string;
                      customAttributes: Array<{ __typename?: 'Attribute'; key: string; value?: string | undefined }>;
                      discountAllocations: Array<{
                          __typename?: 'DiscountAllocation';
                          allocatedAmount: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
                          discountApplication:
                              | {
                                    __typename?: 'AutomaticDiscountApplication';
                                    allocationMethod: Types.DiscountApplicationAllocationMethod;
                                    targetSelection: Types.DiscountApplicationTargetSelection;
                                    targetType: Types.DiscountApplicationTargetType;
                                }
                              | {
                                    __typename?: 'DiscountCodeApplication';
                                    allocationMethod: Types.DiscountApplicationAllocationMethod;
                                    targetSelection: Types.DiscountApplicationTargetSelection;
                                    targetType: Types.DiscountApplicationTargetType;
                                }
                              | {
                                    __typename?: 'ManualDiscountApplication';
                                    allocationMethod: Types.DiscountApplicationAllocationMethod;
                                    targetSelection: Types.DiscountApplicationTargetSelection;
                                    targetType: Types.DiscountApplicationTargetType;
                                }
                              | {
                                    __typename?: 'ScriptDiscountApplication';
                                    allocationMethod: Types.DiscountApplicationAllocationMethod;
                                    targetSelection: Types.DiscountApplicationTargetSelection;
                                    targetType: Types.DiscountApplicationTargetType;
                                };
                      }>;
                      unitPrice?:
                          | { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode }
                          | undefined;
                      variant?:
                          | {
                                __typename?: 'ProductVariant';
                                id: string;
                                title: string;
                                sku?: string | undefined;
                                image?: { __typename?: 'Image'; altText?: string | undefined; url: string } | undefined;
                                price: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
                                compareAtPrice?:
                                    | { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode }
                                    | undefined;
                                unitPrice?:
                                    | { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode }
                                    | undefined;
                                unitPriceMeasurement?:
                                    | {
                                          __typename?: 'UnitPriceMeasurement';
                                          measuredType?: Types.UnitPriceMeasurementMeasuredType | undefined;
                                          quantityUnit?: Types.UnitPriceMeasurementMeasuredUnit | undefined;
                                          quantityValue: number;
                                          referenceUnit?: Types.UnitPriceMeasurementMeasuredUnit | undefined;
                                          referenceValue: number;
                                      }
                                    | undefined;
                                product: { __typename?: 'Product'; id: string; title: string; handle: string };
                            }
                          | undefined;
                  }>;
              };
              lineItemsSubtotalPrice: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
              shippingAddress?:
                  | {
                        __typename: 'MailingAddress';
                        id: string;
                        address1?: string | undefined;
                        address2?: string | undefined;
                        city?: string | undefined;
                        firstName?: string | undefined;
                        lastName?: string | undefined;
                        company?: string | undefined;
                        country?: string | undefined;
                        phone?: string | undefined;
                        province?: string | undefined;
                        provinceCode?: string | undefined;
                        zip?: string | undefined;
                        countryCode?: Types.CountryCode | undefined;
                    }
                  | undefined;
              shippingDiscountAllocations: Array<{
                  __typename?: 'DiscountAllocation';
                  allocatedAmount: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
              }>;
              shippingLine?:
                  | {
                        __typename?: 'ShippingRate';
                        handle: string;
                        title: string;
                        price: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
                    }
                  | undefined;
              subtotalPrice: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
              totalDuties?: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode } | undefined;
              totalPrice: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
              totalTax: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
              discountApplications: {
                  __typename?: 'DiscountApplicationConnection';
                  nodes: Array<
                      | {
                            __typename?: 'AutomaticDiscountApplication';
                            allocationMethod: Types.DiscountApplicationAllocationMethod;
                            targetSelection: Types.DiscountApplicationTargetSelection;
                            targetType: Types.DiscountApplicationTargetType;
                            value:
                                | { __typename: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode }
                                | { __typename: 'PricingPercentageValue'; percentage: number };
                        }
                      | {
                            __typename?: 'DiscountCodeApplication';
                            code: string;
                            allocationMethod: Types.DiscountApplicationAllocationMethod;
                            targetSelection: Types.DiscountApplicationTargetSelection;
                            targetType: Types.DiscountApplicationTargetType;
                            value:
                                | { __typename: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode }
                                | { __typename: 'PricingPercentageValue'; percentage: number };
                        }
                      | {
                            __typename?: 'ManualDiscountApplication';
                            allocationMethod: Types.DiscountApplicationAllocationMethod;
                            targetSelection: Types.DiscountApplicationTargetSelection;
                            targetType: Types.DiscountApplicationTargetType;
                            value:
                                | { __typename: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode }
                                | { __typename: 'PricingPercentageValue'; percentage: number };
                        }
                      | {
                            __typename?: 'ScriptDiscountApplication';
                            allocationMethod: Types.DiscountApplicationAllocationMethod;
                            targetSelection: Types.DiscountApplicationTargetSelection;
                            targetType: Types.DiscountApplicationTargetType;
                            value:
                                | { __typename: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode }
                                | { __typename: 'PricingPercentageValue'; percentage: number };
                        }
                  >;
              };
          }
        | { __typename?: 'CheckoutLineItem'; id: string }
        | { __typename?: 'Collection'; id: string }
        | { __typename?: 'Comment'; id: string }
        | { __typename?: 'Company'; id: string }
        | { __typename?: 'CompanyContact'; id: string }
        | { __typename?: 'CompanyLocation'; id: string }
        | { __typename?: 'ComponentizableCartLine'; id: string }
        | { __typename?: 'ExternalVideo'; id: string }
        | { __typename?: 'GenericFile'; id: string }
        | { __typename?: 'Location'; id: string }
        | { __typename?: 'MailingAddress'; id: string }
        | { __typename?: 'Market'; id: string }
        | { __typename?: 'MediaImage'; id: string }
        | { __typename?: 'MediaPresentation'; id: string }
        | { __typename?: 'Menu'; id: string }
        | { __typename?: 'MenuItem'; id: string }
        | { __typename?: 'Metafield'; id: string }
        | { __typename?: 'Metaobject'; id: string }
        | { __typename?: 'Model3d'; id: string }
        | { __typename?: 'Order'; id: string }
        | { __typename?: 'Page'; id: string }
        | { __typename?: 'Payment'; id: string }
        | { __typename?: 'Product'; id: string }
        | { __typename?: 'ProductOption'; id: string }
        | { __typename?: 'ProductVariant'; id: string }
        | { __typename?: 'Shop'; id: string }
        | { __typename?: 'ShopPolicy'; id: string }
        | { __typename?: 'UrlRedirect'; id: string }
        | { __typename?: 'Video'; id: string }
        | undefined;
};

export const GetAvailableShippingRatesDocument = gql`
    query GetAvailableShippingRates($checkoutId: ID!, $country: CountryCode = SE) @inContext(country: $country) {
        node(id: $checkoutId) {
            id
            ... on Checkout {
                ...CheckoutWithShippingRates
            }
        }
    }
    ${CheckoutWithShippingRatesFragmentDoc}
`;

/**
 * __useGetAvailableShippingRatesQuery__
 *
 * To run a query within a React component, call `useGetAvailableShippingRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableShippingRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableShippingRatesQuery({
 *   variables: {
 *      checkoutId: // value for 'checkoutId'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetAvailableShippingRatesQuery(
    baseOptions: Apollo.QueryHookOptions<GetAvailableShippingRatesQuery, GetAvailableShippingRatesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetAvailableShippingRatesQuery, GetAvailableShippingRatesQueryVariables>(
        GetAvailableShippingRatesDocument,
        options,
    );
}
export function useGetAvailableShippingRatesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAvailableShippingRatesQuery, GetAvailableShippingRatesQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetAvailableShippingRatesQuery, GetAvailableShippingRatesQueryVariables>(
        GetAvailableShippingRatesDocument,
        options,
    );
}
export type GetAvailableShippingRatesQueryHookResult = ReturnType<typeof useGetAvailableShippingRatesQuery>;
export type GetAvailableShippingRatesLazyQueryHookResult = ReturnType<typeof useGetAvailableShippingRatesLazyQuery>;
export type GetAvailableShippingRatesQueryResult = Apollo.QueryResult<
    GetAvailableShippingRatesQuery,
    GetAvailableShippingRatesQueryVariables
>;
