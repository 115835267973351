import * as Types from '@lib/machine-parts/types/graphql/storefront';

import { gql } from '@apollo/client';
import { CheckoutFragmentDoc } from './checkoutFragment.generated';
export type CheckoutWithShippingRatesFragment = {
    __typename?: 'Checkout';
    id: string;
    webUrl: string;
    currencyCode: Types.CurrencyCode;
    email?: string | undefined;
    note?: string | undefined;
    taxExempt: boolean;
    taxesIncluded: boolean;
    availableShippingRates?:
        | {
              __typename?: 'AvailableShippingRates';
              ready: boolean;
              shippingRates?:
                  | Array<{
                        __typename?: 'ShippingRate';
                        handle: string;
                        title: string;
                        price: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
                    }>
                  | undefined;
          }
        | undefined;
    buyerIdentity: { __typename?: 'CheckoutBuyerIdentity'; countryCode?: Types.CountryCode | undefined };
    customAttributes: Array<{ __typename?: 'Attribute'; key: string; value?: string | undefined }>;
    lineItems: {
        __typename?: 'CheckoutLineItemConnection';
        nodes: Array<{
            __typename?: 'CheckoutLineItem';
            id: string;
            quantity: number;
            title: string;
            customAttributes: Array<{ __typename?: 'Attribute'; key: string; value?: string | undefined }>;
            discountAllocations: Array<{
                __typename?: 'DiscountAllocation';
                allocatedAmount: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
                discountApplication:
                    | {
                          __typename?: 'AutomaticDiscountApplication';
                          allocationMethod: Types.DiscountApplicationAllocationMethod;
                          targetSelection: Types.DiscountApplicationTargetSelection;
                          targetType: Types.DiscountApplicationTargetType;
                      }
                    | {
                          __typename?: 'DiscountCodeApplication';
                          allocationMethod: Types.DiscountApplicationAllocationMethod;
                          targetSelection: Types.DiscountApplicationTargetSelection;
                          targetType: Types.DiscountApplicationTargetType;
                      }
                    | {
                          __typename?: 'ManualDiscountApplication';
                          allocationMethod: Types.DiscountApplicationAllocationMethod;
                          targetSelection: Types.DiscountApplicationTargetSelection;
                          targetType: Types.DiscountApplicationTargetType;
                      }
                    | {
                          __typename?: 'ScriptDiscountApplication';
                          allocationMethod: Types.DiscountApplicationAllocationMethod;
                          targetSelection: Types.DiscountApplicationTargetSelection;
                          targetType: Types.DiscountApplicationTargetType;
                      };
            }>;
            unitPrice?: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode } | undefined;
            variant?:
                | {
                      __typename?: 'ProductVariant';
                      id: string;
                      title: string;
                      sku?: string | undefined;
                      image?: { __typename?: 'Image'; altText?: string | undefined; url: string } | undefined;
                      price: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
                      compareAtPrice?:
                          | { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode }
                          | undefined;
                      unitPrice?:
                          | { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode }
                          | undefined;
                      unitPriceMeasurement?:
                          | {
                                __typename?: 'UnitPriceMeasurement';
                                measuredType?: Types.UnitPriceMeasurementMeasuredType | undefined;
                                quantityUnit?: Types.UnitPriceMeasurementMeasuredUnit | undefined;
                                quantityValue: number;
                                referenceUnit?: Types.UnitPriceMeasurementMeasuredUnit | undefined;
                                referenceValue: number;
                            }
                          | undefined;
                      product: { __typename?: 'Product'; id: string; title: string; handle: string };
                  }
                | undefined;
        }>;
    };
    lineItemsSubtotalPrice: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
    shippingAddress?:
        | {
              __typename: 'MailingAddress';
              id: string;
              address1?: string | undefined;
              address2?: string | undefined;
              city?: string | undefined;
              firstName?: string | undefined;
              lastName?: string | undefined;
              company?: string | undefined;
              country?: string | undefined;
              phone?: string | undefined;
              province?: string | undefined;
              provinceCode?: string | undefined;
              zip?: string | undefined;
              countryCode?: Types.CountryCode | undefined;
          }
        | undefined;
    shippingDiscountAllocations: Array<{
        __typename?: 'DiscountAllocation';
        allocatedAmount: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
    }>;
    shippingLine?:
        | {
              __typename?: 'ShippingRate';
              handle: string;
              title: string;
              price: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
          }
        | undefined;
    subtotalPrice: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
    totalDuties?: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode } | undefined;
    totalPrice: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
    totalTax: { __typename?: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode };
    discountApplications: {
        __typename?: 'DiscountApplicationConnection';
        nodes: Array<
            | {
                  __typename?: 'AutomaticDiscountApplication';
                  allocationMethod: Types.DiscountApplicationAllocationMethod;
                  targetSelection: Types.DiscountApplicationTargetSelection;
                  targetType: Types.DiscountApplicationTargetType;
                  value:
                      | { __typename: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode }
                      | { __typename: 'PricingPercentageValue'; percentage: number };
              }
            | {
                  __typename?: 'DiscountCodeApplication';
                  code: string;
                  allocationMethod: Types.DiscountApplicationAllocationMethod;
                  targetSelection: Types.DiscountApplicationTargetSelection;
                  targetType: Types.DiscountApplicationTargetType;
                  value:
                      | { __typename: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode }
                      | { __typename: 'PricingPercentageValue'; percentage: number };
              }
            | {
                  __typename?: 'ManualDiscountApplication';
                  allocationMethod: Types.DiscountApplicationAllocationMethod;
                  targetSelection: Types.DiscountApplicationTargetSelection;
                  targetType: Types.DiscountApplicationTargetType;
                  value:
                      | { __typename: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode }
                      | { __typename: 'PricingPercentageValue'; percentage: number };
              }
            | {
                  __typename?: 'ScriptDiscountApplication';
                  allocationMethod: Types.DiscountApplicationAllocationMethod;
                  targetSelection: Types.DiscountApplicationTargetSelection;
                  targetType: Types.DiscountApplicationTargetType;
                  value:
                      | { __typename: 'MoneyV2'; amount: string; currencyCode: Types.CurrencyCode }
                      | { __typename: 'PricingPercentageValue'; percentage: number };
              }
        >;
    };
};

export const CheckoutWithShippingRatesFragmentDoc = gql`
    fragment CheckoutWithShippingRates on Checkout {
        ...Checkout
        availableShippingRates {
            ready
            shippingRates {
                handle
                price {
                    amount
                    currencyCode
                }
                title
            }
        }
    }
    ${CheckoutFragmentDoc}
`;
