import * as Types from '@lib/machine-parts/types/graphql/storefront';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCheckoutCompletedTimeQueryVariables = Types.Exact<{
    checkoutId: Types.Scalars['ID'];
    country?: Types.InputMaybe<Types.CountryCode>;
}>;

export type GetCheckoutCompletedTimeQuery = {
    __typename?: 'QueryRoot';
    node?:
        | { __typename?: 'AppliedGiftCard'; id: string }
        | { __typename?: 'Article'; id: string }
        | { __typename?: 'Blog'; id: string }
        | { __typename?: 'Cart'; id: string }
        | { __typename?: 'CartLine'; id: string }
        | { __typename?: 'Checkout'; completedAt?: string | undefined; id: string }
        | { __typename?: 'CheckoutLineItem'; id: string }
        | { __typename?: 'Collection'; id: string }
        | { __typename?: 'Comment'; id: string }
        | { __typename?: 'Company'; id: string }
        | { __typename?: 'CompanyContact'; id: string }
        | { __typename?: 'CompanyLocation'; id: string }
        | { __typename?: 'ComponentizableCartLine'; id: string }
        | { __typename?: 'ExternalVideo'; id: string }
        | { __typename?: 'GenericFile'; id: string }
        | { __typename?: 'Location'; id: string }
        | { __typename?: 'MailingAddress'; id: string }
        | { __typename?: 'Market'; id: string }
        | { __typename?: 'MediaImage'; id: string }
        | { __typename?: 'MediaPresentation'; id: string }
        | { __typename?: 'Menu'; id: string }
        | { __typename?: 'MenuItem'; id: string }
        | { __typename?: 'Metafield'; id: string }
        | { __typename?: 'Metaobject'; id: string }
        | { __typename?: 'Model3d'; id: string }
        | { __typename?: 'Order'; id: string }
        | { __typename?: 'Page'; id: string }
        | { __typename?: 'Payment'; id: string }
        | { __typename?: 'Product'; id: string }
        | { __typename?: 'ProductOption'; id: string }
        | { __typename?: 'ProductVariant'; id: string }
        | { __typename?: 'Shop'; id: string }
        | { __typename?: 'ShopPolicy'; id: string }
        | { __typename?: 'UrlRedirect'; id: string }
        | { __typename?: 'Video'; id: string }
        | undefined;
};

export const GetCheckoutCompletedTimeDocument = gql`
    query GetCheckoutCompletedTime($checkoutId: ID!, $country: CountryCode = SE) @inContext(country: $country) {
        node(id: $checkoutId) {
            id
            ... on Checkout {
                completedAt
            }
        }
    }
`;

/**
 * __useGetCheckoutCompletedTimeQuery__
 *
 * To run a query within a React component, call `useGetCheckoutCompletedTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckoutCompletedTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckoutCompletedTimeQuery({
 *   variables: {
 *      checkoutId: // value for 'checkoutId'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useGetCheckoutCompletedTimeQuery(
    baseOptions: Apollo.QueryHookOptions<GetCheckoutCompletedTimeQuery, GetCheckoutCompletedTimeQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetCheckoutCompletedTimeQuery, GetCheckoutCompletedTimeQueryVariables>(
        GetCheckoutCompletedTimeDocument,
        options,
    );
}
export function useGetCheckoutCompletedTimeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetCheckoutCompletedTimeQuery, GetCheckoutCompletedTimeQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetCheckoutCompletedTimeQuery, GetCheckoutCompletedTimeQueryVariables>(
        GetCheckoutCompletedTimeDocument,
        options,
    );
}
export type GetCheckoutCompletedTimeQueryHookResult = ReturnType<typeof useGetCheckoutCompletedTimeQuery>;
export type GetCheckoutCompletedTimeLazyQueryHookResult = ReturnType<typeof useGetCheckoutCompletedTimeLazyQuery>;
export type GetCheckoutCompletedTimeQueryResult = Apollo.QueryResult<
    GetCheckoutCompletedTimeQuery,
    GetCheckoutCompletedTimeQueryVariables
>;
