'use client';

import { useMutation } from '@tanstack/react-query';
import { CustomerAddressUpdateInput } from '../interfaces';
import { MailingAddress } from '@shopify/hydrogen-react/storefront-api-types';

export function useUpdateCustomerAddressMutation({
    onSuccess,
    onError,
    type,
}: {
    onSuccess?: (updatedAddress: Omit<MailingAddress, 'formatted'>, args: CustomerAddressUpdateInput) => void;
    onError?: () => void;
    type: 'shipping' | 'billing';
}) {
    return useMutation<Omit<MailingAddress, 'formatted'>, Error, CustomerAddressUpdateInput>(
        ['update-user-address'],
        async (args?: CustomerAddressUpdateInput): Promise<MailingAddress> => {
            const response = await fetch(`/api/account/address/update-${type}`, {
                method: 'POST',
                body: JSON.stringify(args),
            });
            if (!response.ok) throw new Error('Could not update address');
            return response.json();
        },
        {
            onSuccess,
            onError,
        },
    );
}
