'use client';

import { omit } from 'lodash';
import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
    useTransition,
} from 'react';

import { UseCustomerDataResult } from '@lib/machine-parts/storefront/account/data-access';
import { formatLineItemForCheckout, PaymentMethod } from '@lib/machine-parts/storefront/checkout/utils';
import { extractId, LocalStorageKey } from '@lib/machine-parts/storefront/utils';
import { useCart } from '@shopify/hydrogen-react';
import { MailingAddress } from '@shopify/hydrogen-react/storefront-api-types';

import { useCheckoutApi } from './checkoutApi/useCheckoutApi';
import { CheckoutFragment } from './fragments';
import { CheckoutContext, UseCheckoutProviderProps } from './interfaces/CheckoutContext';
import { LineItem } from './interfaces/LineItem';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const _CheckoutContext = createContext<CheckoutContext>(undefined!);

export function CheckoutV2ProviderClient({
    children,
    customer,
    ...props
}: PropsWithChildren<UseCheckoutProviderProps>) {
    const { status, lines = [], discountCodes, discountCodesUpdate, buyerIdentityUpdate } = useCart();
    const [changingCurrency, changeCurrencyTransaction] = useTransition();

    const {
        create: apiCreate,
        complete: apiComplete,
        updateDiscountCode: apiUpdateDiscountCode,
        replaceLineItems: apiReplaceLineItems,
        ...state
    } = useCheckoutApi({
        ...props,
        changingCurrency,
        defaultAddress:
            customer.addresses?.find(({ id }) => extractId(id) === customer.defaultShippingAddressId) ??
            customer.addresses?.at(0),
        defaultBillingAddress:
            customer.billingAddresses?.find((address) => address.default) ?? customer.billingAddresses?.at(0),
        customer,
    });

    const [discountCode, setDiscountCode] = useState<string | undefined>();
    const [discountCodeError, setDiscountCodeError] = useState<boolean>();
    const [lineItems, setLineItems] = useState<LineItem[]>([]);
    const [shippingAddressOptions, setShippingAddressOptions] = useState<Omit<MailingAddress, 'formatted'>[]>(
        customer.addresses ?? [],
    );
    const [billingAddressOptions, setBillingAddressOptions] = useState<
        (Omit<MailingAddress, 'formatted' | 'id'> & { id?: string })[]
    >(customer.billingAddresses ?? []);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.DEPOSIT);

    useEffect(() => {
        if (changingCurrency || !state.checkoutId) return;

        const updateLineItems = async () => {
            if (lines?.length > 0) {
                const itemsToReplace = lines
                    .filter((line) => line?.id)
                    .map((line) => ({
                        quantity: line!.quantity!,
                        variantId: line!.merchandise!.id!,
                    }));

                const variantsA = itemsToReplace.map((item) => `${item.quantity} - ${item.variantId}`);
                const variantsB = lineItems.map((item) => `${item.quantity} - ${item.variantId}`);

                // Check if variants are the same
                if (
                    variantsA.length !== variantsB.length ||
                    variantsA.some((v) => !variantsB.includes(v)) ||
                    variantsB.some((v) => !variantsA.includes(v))
                ) {
                    console.log('Replacing line items');
                    setLineItems(await apiReplaceLineItems(itemsToReplace));
                }
            }
        };

        updateLineItems();
    }, [apiReplaceLineItems, changingCurrency, lineItems, lines, state.checkoutId]);

    useEffect(() => {
        buyerIdentityUpdate({
            email: state.customerInfo?.email ?? customer.email,
            phone: state.customerInfo?.phone,
            customerAccessToken: null,
        });
        // buyerIdentityUpdate makes this an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lineItems, customer.email, state.customerInfo?.email, state.customerInfo?.phone]);

    useEffect(() => {
        setDiscountCodeError(false);
        if (discountCodes?.[0]?.code !== discountCode) {
            const newDiscountCode = discountCodes?.[0]?.applicable ? discountCodes?.[0]?.code : undefined;
            if (state.discountCode !== newDiscountCode) {
                apiUpdateDiscountCode(newDiscountCode ?? null);
            }
            setDiscountCodeError(!discountCodes?.[0]?.applicable);
            setDiscountCode(newDiscountCode);

            newDiscountCode
                ? localStorage.setItem(LocalStorageKey.DISCOUNT_CODE, newDiscountCode)
                : localStorage.removeItem(LocalStorageKey.DISCOUNT_CODE);
        }
    }, [apiUpdateDiscountCode, discountCode, discountCodes, state.discountCode]);

    const create: CheckoutContext['create'] = useCallback(
        (payload, countryCode) => {
            return apiCreate(
                {
                    discountCode: discountCode,
                    ...payload,
                    lineItems: payload?.lineItems?.length ? payload.lineItems : formatLineItemForCheckout(lines),
                },
                countryCode,
            );
        },
        [apiCreate, discountCode, lines],
    );

    const updateDiscountCode = useCallback(
        async (code: string | null) => {
            discountCodesUpdate(code ? [code] : []);

            if (!code) {
                setDiscountCodeError(false);
                setDiscountCode(undefined);
                localStorage.removeItem(LocalStorageKey.DISCOUNT_CODE);
            }
        },
        [discountCodesUpdate],
    );

    // Initial load of the checkout
    useEffect(() => {
        if (state.checkoutId || status !== 'idle') return;
        const localCheckout = localStorage.getItem(LocalStorageKey.CHECKOUT);
        const discountCode = localStorage.getItem(LocalStorageKey.DISCOUNT_CODE) ?? undefined;
        if (localCheckout) {
            const checkout: CheckoutFragment = JSON.parse(localCheckout);

            if (checkout.id) {
                state.handleCheckoutApiPricingChange(checkout);
                state.setCheckoutId(checkout.id);
                state.setCheckoutUrl(checkout.webUrl);
                setDiscountCode(discountCode);
            }
        }

        create({ lineItems: formatLineItemForCheckout(lines), discountCode }).finally(() =>
            updateDiscountCode(discountCode ?? null),
        );
    }, [create, lines, state, state.checkoutId, status, updateDiscountCode]);

    const updateCustomerInfo: CheckoutContext['updateCustomerInfo'] = useCallback(
        (payload) => {
            let customerData: CheckoutContext['customerInfo'] = state.customerInfo;
            if (payload.email && payload.email !== state.customerInfo?.email) {
                customerData = { ...customerData, email: payload.email };
            }
            if (payload.firstName) {
                customerData = { ...customerData, firstName: payload.firstName };
            }
            if (payload.lastName) customerData = { ...customerData, lastName: payload.lastName };
            if (payload.phone && payload.phone !== state.customerInfo?.phone) {
                customerData = { ...customerData, phone: payload.phone };
            }
            if (payload.countryCallingCode) {
                customerData = { ...customerData, countryCallingCode: payload.countryCallingCode };
            }
            if (payload.vatNumber) {
                customerData = { ...customerData, vatNumber: payload.vatNumber };
            }
            state.setCustomerInfo(customerData);
        },
        [state.customerInfo, state.setCustomerInfo],
    );

    const updateCustomerAddressOptions: CheckoutContext['updateCustomerAddressOptions'] = useCallback(
        (customer: UseCustomerDataResult) => {
            setShippingAddressOptions(customer.addresses ?? []);
            setBillingAddressOptions(customer.billingAddresses ?? []);
        },
        [],
    );

    const updatePaymentMethod: CheckoutContext['updatePaymentMethod'] = useCallback(
        (method) => {
            if (method === paymentMethod) return;
            setPaymentMethod(method);
        },
        [paymentMethod],
    );

    const updateNote: CheckoutContext['updateNote'] = useCallback(
        async (payload) => {
            const newNote = {
                ...state.note,
                ...payload,
                // Don't update invoice address to the default address, empty instead
                invoice:
                    state.customerInfo?.email && payload.invoice === state.customerInfo?.email
                        ? ''
                        : payload.invoice?.length
                        ? payload.invoice
                        : state.note.invoice ?? '',
            };

            state.setNote(newNote);
        },
        [state],
    );

    const updateCountry: CheckoutContext['updateCountry'] = useCallback(
        async (country) => {
            changeCurrencyTransaction(async () => {
                await create(undefined, country);
                updateNote(state.note);
            });
            await state.getAvailableShippingRates();
        },
        [create, state, updateNote],
    );

    useEffect(() => {
        buyerIdentityUpdate({
            email: state.customerInfo?.email,
            phone: state.customerInfo?.phone,
            customerAccessToken: null,
        });
        // buyerIdentityUpdate makes this an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.customerInfo?.email, state.customerInfo?.phone]);

    const updateCheckoutAfterLogin: CheckoutContext['updateCheckoutAfterLogin'] = useCallback(
        async (customer: UseCustomerDataResult) => {
            const checkoutId = await create();
            updateCustomerAddressOptions(customer);
            updateCustomerInfo(customer);
            const shippingAddress =
                customer.addresses?.find(({ id }) => extractId(id) === customer.defaultShippingAddressId) ??
                customer.addresses?.at(0);
            if (shippingAddress)
                state.updateShippingAddress(omit(shippingAddress, 'id', 'provinceCode', 'countryCode'), checkoutId);

            const billingAddress =
                customer.billingAddresses?.find((address) => address.default) ?? customer.billingAddresses?.at(0);
            if (billingAddress) state.updateBillingAddress(billingAddress);
        },
        [create, state, updateCustomerAddressOptions, updateCustomerInfo],
    );

    const complete: CheckoutContext['complete'] = useCallback(
        async (payload) => {
            await apiComplete(payload);
            discountCodesUpdate([]);
            setDiscountCode(undefined);
        },
        [apiComplete, discountCodesUpdate],
    );

    const value: CheckoutContext = useMemo(() => {
        return {
            ...state,
            create,
            updateCustomerInfo,
            updatePaymentMethod,
            updateCustomerAddressOptions,
            paymentMethod,
            lineItems,
            shippingAddressOptions,
            setShippingAddressOptions,
            billingAddressOptions,
            setBillingAddressOptions,
            updateDiscountCode,
            discountCode,
            discountCodeError,
            complete,
            replaceLineItems: apiReplaceLineItems,
            updateNote,
            updateCountry,
            updateCheckoutAfterLogin,
        };
    }, [
        state,
        create,
        updateCustomerInfo,
        updatePaymentMethod,
        updateCustomerAddressOptions,
        paymentMethod,
        lineItems,
        shippingAddressOptions,
        billingAddressOptions,
        updateDiscountCode,
        discountCode,
        discountCodeError,
        complete,
        apiReplaceLineItems,
        updateNote,
        updateCountry,
        updateCheckoutAfterLogin,
    ]);

    // eslint-disable-next-line react/jsx-pascal-case
    return <_CheckoutContext.Provider value={value}>{children}</_CheckoutContext.Provider>;
}

export const useCheckout = () => useContext(_CheckoutContext);
