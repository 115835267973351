import axios from 'axios';

import { MakeCheckoutRequestProps } from './interfaces';

export type CheckoutApiResponse<T> = { checkout: T; errors: unknown[] };

export type MakeCheckoutRequestResult<ReturnPayload> =
    | {
          checkout: ReturnPayload;
          orderId?: string;
      }
    | undefined;

export async function makeCheckoutRequest<ReturnPayload = undefined>({
    action,
    payload,
    checkoutId,
    countryCode,
    currentCheckout: checkoutProps,
}: MakeCheckoutRequestProps): Promise<MakeCheckoutRequestResult<ReturnPayload>> {
    const currentCheckout = {
        ...(checkoutProps ?? {}),
        buyerIdentity: { countryCode },
        ...(checkoutId && { id: checkoutId }),
    };
    try {
        const { data } = await axios.post<CheckoutApiResponse<ReturnPayload>>('/api/checkout', {
            action,
            payload,
            currentCheckout,
        });

        if (data.errors) {
            console.error(JSON.stringify(data.errors));
            return undefined;
        }

        return data;
    } catch (e) {
        console.error('checkoutApi', JSON.stringify(e));
        return undefined;
    }
}
