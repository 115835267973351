import { CustomerStatus } from './interfaces';

export interface CustomerStatusOutput {
    state: CustomerStatus;
}

export interface CheckCustomerStatusProps {
    email?: string;
    phone?: string;
}

export async function checkStatus(body: CheckCustomerStatusProps): Promise<CustomerStatusOutput> {
    return await fetch('/api/account/status', {
        method: 'POST',
        body: JSON.stringify(body),
    }).then((response) => response.json());
}
