'use client';

import { omit } from 'lodash';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect, useState } from 'react';

import { CheckoutNote, formatNote } from '@lib/machine-parts/storefront/checkout/utils';
import {
    extractId,
    formatRoute,
    LocalStorageKey,
    Route,
    ShippableCountry,
    useMixpanel,
} from '@lib/machine-parts/storefront/utils';
import { useCart } from '@shopify/hydrogen-react';
import { MailingAddress, MailingAddressInput, MoneyV2 } from '@shopify/hydrogen-react/storefront-api-types';

import { CheckoutAction } from '../constants/checkoutActions';
import { CheckoutFragment, CheckoutWithShippingRatesFragment } from '../fragments';
import {
    CheckoutApiContext,
    CheckoutContext,
    CheckoutShippingMethod,
    UseCheckoutApiProps,
} from '../interfaces/CheckoutContext';
import { CheckoutPrices } from '../interfaces/CheckoutPrices';
import { AVAILABLE_SHIPPING_POLL_DELAY, AVAILABLE_SHIPPING_POLL_TIMEOUT } from './constants';
import { MakeCheckoutRequestProps } from './interfaces';
import { makeCheckoutRequest } from './makeCheckoutRequest';

export function useCheckoutApi({
    countryCode,
    changingCurrency,
    defaultAddress,
    defaultBillingAddress,
    customer,
}: UseCheckoutApiProps): CheckoutApiContext {
    const mixpanel = useMixpanel();
    const { push: navigate, refresh } = useRouter();

    const [loading, setLoading] = useState(false);
    const [loadingShippingAddress, setLoadingShippingAddress] = useState(false);
    const [loadingShippingMethods, setLoadingShippingMethods] = useState(false);
    const [loadingComplete, setLoadingComplete] = useState(false);
    // Suggestion: Maybe useReducer is better here?
    const [note, setNote] = useState<CheckoutNote>({ invoice: customer?.email ?? '', note: '' });
    const [customerInfo, setCustomerInfo] = useState<UseCheckoutApiProps['customer']>(customer);
    const { lines = [] } = useCart();

    const [checkoutId, setCheckoutId] = useState<string | undefined>();
    const [shippingAddress, setShippingAddress] = useState<Omit<MailingAddress, 'formatted'> | undefined>(
        defaultAddress,
    );
    const [billingAddress, setBillingAddress] = useState<
        (Omit<MailingAddress, 'formatted' | 'id'> & { id?: string }) | undefined
    >(defaultBillingAddress);
    const [costs, setCosts] = useState<CheckoutPrices>({});
    const [shippingMethod, setShippingMethod] = useState<CheckoutShippingMethod | undefined>();
    const [availableShippingMethods, setAvailableShippingMethods] = useState<CheckoutShippingMethod[]>([]);
    const [checkoutUrl, setCheckoutUrl] = useState<string | undefined>();
    const [discountCode, setDiscountCode] = useState<string | undefined>();

    const updateLocalCheckout = useCallback((checkout?: string) => {
        if (!checkout) {
            localStorage.removeItem(LocalStorageKey.CHECKOUT);
        } else {
            try {
                const oldCheckout = JSON.parse(localStorage.getItem(LocalStorageKey.CHECKOUT) ?? '{}');
                const newCheckout = { ...oldCheckout, ...JSON.parse(checkout) };
                localStorage.setItem(LocalStorageKey.CHECKOUT, JSON.stringify(newCheckout));
            } catch {
                localStorage.setItem(LocalStorageKey.CHECKOUT, checkout);
            }
        }
    }, []);

    const handleCheckoutApiPricingChange = useCallback(
        (
            checkout: Pick<
                Partial<CheckoutFragment>,
                'lineItemsSubtotalPrice' | 'lineItems' | 'shippingLine' | 'totalTax' | 'totalPrice'
            >,
        ) => {
            const discount = checkout.lineItems?.nodes.reduce<MoneyV2>(
                (acc, curr) => {
                    const discountAllocation = curr.discountAllocations[0]?.allocatedAmount;

                    if (!discountAllocation) {
                        return acc;
                    }

                    return {
                        amount: (parseFloat(acc.amount) + parseFloat(discountAllocation.amount)).toFixed(2),
                        currencyCode: discountAllocation.currencyCode,
                    };
                },
                { amount: '0.0', currencyCode: 'EUR' },
            );

            setCosts({
                subtotal: checkout.lineItemsSubtotalPrice,
                discount,
                taxes: checkout.totalTax,
                shipping: checkout.shippingLine?.price,
                total: checkout.totalPrice,
            });
        },
        [setCosts],
    );

    const handleCheckoutApi = useCallback(
        async function callback<PartialCheckout extends Partial<CheckoutWithShippingRatesFragment>>({
            action,
            payload,
            currentCheckout,
            countryCode: payloadCountryCode,
            checkoutId: payLoadCheckoutId,
        }: Omit<MakeCheckoutRequestProps, 'countryCode'> & { countryCode?: string }) {
            if (![CheckoutAction.CREATE].includes(action) && !checkoutId && !payLoadCheckoutId) {
                console.warn(`Checkout API called with action ${action}, but no checkout ID present`);
                return;
            }

            const apiResponse = await makeCheckoutRequest<
                Pick<
                    CheckoutFragment,
                    | 'id'
                    | 'webUrl'
                    | 'lineItemsSubtotalPrice'
                    | 'lineItems'
                    | 'shippingLine'
                    | 'totalTax'
                    | 'totalPrice'
                > &
                    PartialCheckout
            >({
                action,
                payload,
                checkoutId: payLoadCheckoutId ?? checkoutId,
                countryCode: payloadCountryCode ?? countryCode,
                currentCheckout,
            } as MakeCheckoutRequestProps);

            updateLocalCheckout(JSON.stringify(apiResponse?.checkout));

            if (apiResponse?.checkout) {
                handleCheckoutApiPricingChange(apiResponse?.checkout);
                setCheckoutId(apiResponse.checkout.id);
                setCheckoutUrl(apiResponse.checkout.webUrl);
            }

            return apiResponse;
        },
        [checkoutId, countryCode, handleCheckoutApiPricingChange, updateLocalCheckout],
    );

    const updateDiscountCode = useCallback(
        async (discountCode: string | null) => {
            setDiscountCode(discountCode ?? undefined);
            if (discountCode) {
                await handleCheckoutApi({ action: CheckoutAction.ADD_DISCOUNT_CODE, payload: discountCode });
            } else {
                await handleCheckoutApi({ action: CheckoutAction.REMOVE_DISCOUNT_CODE });
            }
        },
        [handleCheckoutApi],
    );

    const updateShippingAddress: CheckoutApiContext['updateShippingAddress'] = useCallback(
        async (address, checkoutId) => {
            setLoading(true);
            setLoadingShippingAddress(true);
            const response = await handleCheckoutApi<Pick<CheckoutFragment, 'shippingAddress'>>({
                action: CheckoutAction.UDPATE_SHIPPING_ADDRESS,
                payload: omit(address, 'countryCode', 'provinceCode'),
                checkoutId,
            });

            if (response?.checkout.shippingAddress) {
                setShippingAddress(response.checkout.shippingAddress);
                setAvailableShippingMethods([]);
            }
            setLoadingShippingAddress(false);
            setLoading(false);
        },
        [handleCheckoutApi],
    );

    const updateBillingAddress: CheckoutApiContext['updateBillingAddress'] = useCallback((address) => {
        setBillingAddress(address);
    }, []);

    // Shipping methods
    const updateShippingMethod: CheckoutContext['updateShippingMethod'] = useCallback(
        async (shippingRateHandle) => {
            setLoading(true);
            setLoadingShippingMethods(true);
            const response = await handleCheckoutApi<Pick<CheckoutFragment, 'shippingLine'>>({
                action: CheckoutAction.UPDATE_SHIPPING_LINE,
                payload: shippingRateHandle,
            });

            if (response?.checkout.shippingLine) {
                setShippingMethod(response.checkout.shippingLine);
            }
            setLoadingShippingMethods(false);
            setLoading(false);
        },
        [handleCheckoutApi],
    );

    const getAvailableShippingRates = useCallback(async () => {
        const response = await handleCheckoutApi<Pick<CheckoutWithShippingRatesFragment, 'availableShippingRates'>>({
            action: CheckoutAction.GET_AVAILABLE_SHIPPING_RATES,
        });
        if (response?.checkout.availableShippingRates?.ready) {
            const newMethods = response.checkout.availableShippingRates.shippingRates ?? [];
            setAvailableShippingMethods(newMethods);
            const shippingRateHandle = response.checkout.availableShippingRates.shippingRates
                ?.sort((a, b) => (Number(a.price.amount) < Number(b.price.amount) ? -1 : 1))
                ?.at(0)?.handle;
            if (shippingRateHandle) updateShippingMethod(shippingRateHandle);
        }
    }, [handleCheckoutApi, updateShippingMethod]);

    useEffect(() => {
        if (
            !shippingAddress ||
            availableShippingMethods.length > 0 ||
            !checkoutId ||
            !lines?.length ||
            // If anything else is loading clear this
            (loading && !loadingShippingMethods) ||
            changingCurrency
        )
            return;

        setLoading(true);
        setLoadingShippingMethods(true);

        function tick() {
            getAvailableShippingRates();
        }

        const id = setInterval(tick, AVAILABLE_SHIPPING_POLL_DELAY);
        setTimeout(() => {
            clearInterval(id);
        }, AVAILABLE_SHIPPING_POLL_TIMEOUT);

        return () => {
            clearInterval(id);
            setLoadingShippingMethods(false);
            setLoading(false);
        };
    }, [
        changingCurrency,
        loading,
        getAvailableShippingRates,
        mixpanel,
        shippingAddress,
        availableShippingMethods.length,
        checkoutId,
        availableShippingMethods,
        lines?.length,
        loadingShippingMethods,
    ]);

    const create: CheckoutApiContext['create'] = useCallback(
        async (payload, countryCode) => {
            setLoadingComplete(false);
            setLoading(true);
            const shippingAddress: MailingAddressInput | undefined =
                payload?.shippingAddress ?? defaultAddress ?? undefined;

            const response = await handleCheckoutApi<Pick<CheckoutFragment, 'shippingAddress'>>({
                action: CheckoutAction.CREATE,
                payload: {
                    ...payload,
                    shippingAddress: omit(shippingAddress, 'id', 'provinceCode', 'countryCode'),
                },
                countryCode,
            });

            if (response) {
                if (response.checkout.shippingAddress) {
                    setShippingAddress(response.checkout.shippingAddress);
                    setBillingAddress(defaultBillingAddress);
                    getAvailableShippingRates();
                }
            }

            setLoading(false);
            return response?.checkout.id;
        },
        [defaultAddress, handleCheckoutApi, defaultBillingAddress, getAvailableShippingRates],
    );

    const replaceLineItems: CheckoutContext['replaceLineItems'] = useCallback(
        async (lineItems) => {
            const checkout = await handleCheckoutApi<
                Pick<CheckoutWithShippingRatesFragment, 'availableShippingRates' | 'lineItems'>
            >({
                action: CheckoutAction.REPLACE_LINE_ITEMS,
                payload: lineItems.map((line) => ({
                    variantId: line.variantId,
                    quantity: line.quantity,
                })),
            });

            // Update the shipping method dynamic pricing
            const rates = checkout?.checkout.availableShippingRates;
            if (rates?.ready && rates?.shippingRates) {
                setAvailableShippingMethods(rates.shippingRates);
                if (rates.shippingRates.find(({ handle }) => handle !== shippingMethod?.handle)) {
                    setShippingMethod(undefined);
                }
            } else {
                setAvailableShippingMethods([]);
            }

            return (
                checkout?.checkout.lineItems?.nodes
                    .filter((line) => !!line.variant)
                    .map((line) => ({
                        id: line.id,
                        variantId: line.variant!.id,
                        quantity: line.quantity,
                    })) ?? []
            );
        },
        [handleCheckoutApi, shippingMethod?.handle],
    );

    const clear: CheckoutApiContext['clear'] = useCallback(() => {
        [
            LocalStorageKey.CHECKOUT,
            LocalStorageKey.SHIPPING_ADDRESS,
            LocalStorageKey.BILLING_ADDRESS,
            LocalStorageKey.USER_INFORMATION,
            LocalStorageKey.NOTE,
        ].forEach((item) => localStorage.removeItem(item));
    }, []);

    const complete: CheckoutContext['complete'] = useCallback(
        async (payload) => {
            setLoading(true);
            setLoadingComplete(true);

            const checkout = localStorage.getItem(LocalStorageKey.CHECKOUT);
            if (!checkout) throw Error('Checkout missing');

            const localStorageCheckout = JSON.parse(checkout);

            const response = await handleCheckoutApi({
                action: CheckoutAction.COMPLETE,
                payload,
                currentCheckout: {
                    ...localStorageCheckout,
                    email: customerInfo?.email,
                    customAttributes: [
                        ...localStorageCheckout.customAttributes,
                        { key: 'vat_number', value: customerInfo?.vatNumber, __typename: 'Attribute' },
                    ],
                    note: formatNote(
                        { ...note, vat: customerInfo?.vatNumber },
                        shippingAddress?.countryCode as ShippableCountry,
                    ),
                    billingAddress: omit(billingAddress, 'id', '__typename'),
                },
            });
            if (response?.orderId) {
                const orderId = extractId(response.orderId)?.split('/').at(-1);
                if (!orderId) throw Error(`Order id invalid (${response.orderId})`);
                navigate(
                    formatRoute(Route.CHECKOUT_ORDER_CONFIRMATION, {
                        context: { orderId },
                        params: { email: customerInfo?.email ?? 'unknown' },
                    }),
                );
                refresh();
                clear();
            }
            setLoading(false);
        },
        [
            handleCheckoutApi,
            customerInfo?.email,
            customerInfo?.vatNumber,
            note,
            countryCode,
            shippingAddress,
            billingAddress,
            navigate,
            refresh,
            clear,
        ],
    );

    return {
        costs,
        create,
        discountCode,
        updateDiscountCode,
        setCustomerInfo,
        updateShippingAddress,
        updateBillingAddress,
        updateShippingMethod,
        handleCheckoutApiPricingChange,
        getAvailableShippingRates,
        loading,
        loadingShippingAddress,
        loadingShippingMethods,
        setLoadingShippingMethods,
        loadingComplete,
        checkoutId,
        setCheckoutId,
        checkoutUrl,
        setCheckoutUrl,
        customerInfo,
        shippingAddress,
        complete,
        clear,
        billingAddress,
        shippingMethod,
        availableShippingMethods,
        replaceLineItems,
        note,
        setNote,
        setLoadingComplete,
    };
}
